import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VitalsPageService {
  
  ecgDataSubject = new Subject();
  temperature1 = new Subject();
  chartClose = new Subject();
  spiroChartData: Subject<any> = new Subject<any>();

  temperature:any;
  spiroMeterReadings: any = [];
  spiroMeterReadingsForTable: any = [];


 
  constructor() {
    // alert('coming to the vital')
   }
}
